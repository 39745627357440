import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"

const DataContext = React.createContext()

export const useData = () => {
  return useContext(DataContext)
}

export const DataProvider = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      allWpSlideshow {
        edges {
          node {
            id
            slideshow {
              description
              logo {
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                  url
                }
              }
            }
          }
        }
      }

      allWpPortfolio {
        edges {
          node {
            id
            title
            featuredImage {
              node {
                sourceUrl
                localFile {
                  childImageSharp {
                    fluid {
                      src
                    }
                  }
                }
              }
            }
            portfolio {
              industry
              portfolioText
              portfolioUrl
              region
            }
          }
        }
      }

       allWpTeam {
    edges {
      node {
        date
        id
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
        team {
          biography
          companies
          jobTitle
          linkedin
          academicBackground
          location
        }
      }
    }
  }

   allWpNew {
    edges {
      node {
        slug
         id
        news {
          excerpt
          content
        }
        date
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
    }
  `)
  const anotherValue = "example"
  //const data = "example"
  const sortedPosts =
    "data.allWpPost.edges.sort((a,b)=>a.node.date<b.node.date?1:-1)"

  return (
    <DataContext.Provider value={{ data, sortedPosts, anotherValue }}>
      {children}
    </DataContext.Provider>
  )
}
